import React from 'react'
import styled from 'styled-components'
import { A, External, HalfLine, InfoBody, InfoSection, InfoStyles } from '../components/Info'
import { useCachedScript, usePageSettings, usePathState } from 'src/lib/hooks_ext'
import { useF, useM, useR, useS } from 'src/lib/hooks'
import api from 'src/lib/api'
import { S } from 'src/lib/util'
import { RATE, SLOTS, Sponsor, use_sponsor_slots, use_sponsors } from 'src/components/individual/sponsors'
import { store } from 'src/lib/store'
import { PieChart, PieChartProps } from 'react-minimal-pie-chart'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const { named_log, strings, maths, objects, lists, colors, list } = window as any
const log = named_log('donoboard')

const color_order = [
  '#0072bd',
  '#d9531a',
  '#edb120',
  '#7e2f8e',
  '#78ac2f',
  '#4dbeee',
  '#a31330',
]
// [
//   '#11b5ae',
//   '#4046ca',
//   '#f68512',
//   '#de3c82',
//   '#7e84fa',
//   '#72e06a',
// ]

export default () => {
  const sponsors = use_sponsors()
  const slots = use_sponsor_slots()
  useF('sponsors', sponsors, slots, log)

  const handle = {
    detail: (...x) => {

    },
  }

  // const [mode, set_mode] = useS('all')
  const [mode, set_mode]: ['claimed'|'all', any] = store.use('donoboard-mode', { default: 'claimed' })
  const last_mode = useR()
  const chart_data = useM(sponsors, slots, mode, () => {
    if (sponsors && slots) {
      const indexed_sponsors = sponsors.map((x, i) => ({
        i,
        ...x
      }))
      const me = indexed_sponsors.find(x => x.name === 'cyrus')
      const actual_sponsors = indexed_sponsors.filter(x => x !== me)

      const data = actual_sponsors.map((x, i) => {
        return {
          index: i,
          title: x.name || 'anonymous',
          value: x.slots,
          actual: x.slots,
          // color: `hsl(${i / actual_sponsors.length * 360}deg 100% 60%)`,
          // color: colors.rgb_to_hex(...colors.hsl_object_to_rgb({ h: 1 - ((i+1) / actual_sponsors.length), s: 1, l: .6 })),
          // color: colors.rgb_to_hex(...colors.hsl_object_to_rgb({ h: 1 - ((i+1) / actual_sponsors.length), s: .85, l: .6 })),
          color: colors.rgb_to_hex(...colors.hsl_object_to_rgb({ h: (1 - .35 - (i / actual_sponsors.length)) + 1 % 1, s: .85, l: .6 })),
          // color: color_order[i % color_order.length] + 'dd',
        }
      }).concat(mode === 'all' ? [{
        index: actual_sponsors.length,
        title: 'unclaimed',
        value: me.slots, actual: me.slots,
        color: '#eeebe6',
      }] : [])
      if (mode === 'all') {
        data.map(x => {
          // x.title = `${x.title} %`
          x.value = Math.ceil(100 * x.value / slots.total)
        })
      }
      log({data})
      return data
    }
  })
  useF('chart', chart_data, log)

  const actual_max = useM(sponsors, () => sponsors && lists.maxxing(sponsors, x => x.slots))
  const actual_sponsors = useM(sponsors, () => sponsors?.filter(x => x.name !== 'cyrus'))
  const max_sponsor = useM(actual_sponsors, () => actual_sponsors && lists.maxxing(actual_sponsors, x => x.slots))
  const second_sponsor = useM(actual_sponsors, () => actual_sponsors && lists.maxxing(actual_sponsors, x => x === max_sponsor ? 0 : x.slots))
  useF(max_sponsor, second_sponsor, () => log({max_sponsor, second_sponsor}))
  const needed_to_overtake = useM(max_sponsor, second_sponsor, () => {
    if (max_sponsor && second_sponsor) {
      const difference = max_sponsor.slots - second_sponsor.slots
      return difference
    }
  })
  // const max_actual_sponsor = useM(max_sponsor, second_sponsor, () => max_sponsor && (max_sponsor.name === 'cyrus' ? second_sponsor : max_sponsor))

  const me = useM(sponsors, () => sponsors?.find(x => x.name === 'cyrus'))

  usePageSettings({
    professional: true,
    uses: objects.new('chart.js'),
    // expand: true,
  })
  return <Style>
    <InfoBody>
      {/* <InfoSection labels={['8334 sponsor slots']}>
        <pre>{strings.json.pretty(sponsors)}</pre>
      </InfoSection> */}
      <InfoSection id='leaderboard-section' labels={[
        // 'leaderboard',
        'donoboard',
        (slots && slots.taken && slots.unclaimed ? `${slots.taken}/` : '') + `${SLOTS} slots taken`,
        // { 'claim a slot': () => parent.open('https://freshman.dev/1', '_blank') },
        // { 'acquire slot(s)': () => parent.open('https://freshman.dev/github-sponsors', '_blank') },
        // { text: 'acquire slot(s)', href: '/coffee/monthly' },
        // { text: 'acquire slot', href: '/coffee/monthly' },
        { text: 'acquire $2/mo slot', href: 'https://freshman.dev/slot' },
      ]}>
        {false && max_sponsor
        ? <div className='pre-line'>
          {actual_max?.name === 'cyrus' ? <span>after me (<b>USERS: UPSURP ME!</b>),&nbsp;</span> : null}
          <b><Sponsor sponsor={max_sponsor} /></b> leads by {needed_to_overtake}
        </div>
        : null}
        <div className='container-scrolled-table'>
          <table className='scrolled-table'>
            <thead>
              {/* <tr className='scrolled-table-border'><td colSpan={5}/></tr> */}
              <tr>
                <td></td>
                <td>name</td>
                <td>source</td>
                <td>slots</td>
                <td>link</td>
              </tr>
              <tr className='scrolled-table-border'><td colSpan={5}/></tr>
            </thead>
            <tbody>
              {sponsors ? sponsors.filter(x => x.name !== 'cyrus').map((entry, i) => {
                const rank = i + 1
                const entry_source_url = {
                  'github.com': 'https://github.com/sponsors/cfreshman?frequency=recurring&sponsor=cfreshman',
                  'stripe.com': 'https://freshman.dev/slot',
                }[entry.source]
                return <>
                  <tr className={`entry`}>
                    <td className='entry-rank'>{rank}</td>
                    <td className='entry-name'><Sponsor sponsor={entry} bold={!!entry.name} /></td>
                    <td className='entry-source'>{entry_source_url ? <A href={entry_source_url}>{entry.source}</A> : entry.source}</td>
                    <td className='entry-slots'>{entry.slots || ''}</td>
                    <td className='entry-url'>{entry.url ? <External href={entry.url} /> : null}</td>
                  </tr>
                </>
              }) : <tr><td colSpan={5}>loading</td></tr>}
              {/* <tr className='scrolled-table-border-bottom'><td colSpan={5}/></tr> */}
            </tbody>
          </table>
        </div>
      </InfoSection>
      <InfoSection labels={[]}>
        {/* {me ? <div>
          <span>{Math.floor(me.slots)} $2/mo slots to go!</span>
        </div> : null} */}
        <div className='row wide end'>
          <div><b><A href='/contact' /></b> to set your link!</div>
        </div>
        {/* <div>not to be confused with <A href='/dinoboard' /> or <A href='/dodoboard' /></div> */}
        {/* <HalfLine /> */}
        {/* {max_sponsor
        ? <div className='pre-line'>
            {actual_max?.name === 'cyrus' ? <span>i'm still #1 - <b>USERS: UPSURP ME!</b>{'\n'}then:&nbsp;</span> : null}
            <Sponsor sponsor={max_sponsor} /> leads by {needed_to_overtake}
        </div>
        : null} */}
      </InfoSection>
      <InfoSection id='chart-section' labels={[
        // 'chart',
        // 'view:',
        ...list('claimed all').map(x => mode === x ? `viewing ${x}` : { [x]: () => set_mode(x) }),
      ]}>
        <div className='chart-container'>
          {chart_data ? <div data-tip="" data-for="chart">
            <PieChart {...{
              data: chart_data,
              startAngle: -90,
              // lengthAngle: -360,
              // animate: true,
              // animationDuration: 200,
              // label: (props: any) => `${props.dataEntry.index + 1}`,
              label: (props: any) => {
                log(props)
                let label = `${props.dataEntry.percentage > 5 ? props.dataEntry.title : props.dataEntry.index + 1}`
                if (!label || label === 'anonymous') {
                  label = `${props.dataEntry.index + 1}`
                }
                // props.dx *= 3/4
                // props.dy *= 3/4
                props.textAnchor = 'middle'
                if (props.dx < -20) props.textAnchor = 'start'
                else if (props.dx > 20) props.textAnchor = 'end'
                return label
              },
              labelStyle: index => {
                let data = chart_data[index]
                log(index, data)
                return S(`
                font-size: 3px;
                font-family: monospace;
                padding: 0 .25em;
                border-radius: 99em;
                // text-shadow: .2em .2em #000, -.2em -.2em #000, -.2em .2em #000, .2em -.2em #000;
                fill: #000;
                // text-shadow: .1em .1em #fff, -.1em -.1em #fff, -.1em .1em #fff, .1em -.1em #fff;
                font-weight: bold;
                `)
              },
              radius: 50,
              labelPosition: 90,
            }} />
            {/* <ReactTooltip id="chart" getContent={() =>
              typeof hovered === 'number' ? makeTooltipContent(chart_data[hovered]) : null
            } /> */}
          </div>
          : null}
        </div>
      </InfoSection>
    </InfoBody>
  </Style>
}

const Style = styled(InfoStyles)`
#leaderboard-section {
  .container-scrolled-table {
    max-height: 17em;
    overflow: auto;
    width: 100%;
    background: gold;
    border: 1px solid #000;

    .scrolled-table {
      border: 0;
      // border: 1px solid #000;
      // border-top: 0; border-bottom: 0;
      thead {
        position: sticky; top: 0px;
        // width: calc(100% - 2px);
        background: inherit;
        // box-shadow: 0 0 0 1px currentcolor;
      }
      .scrolled-table-border, .scrolled-table-border-bottom {
        background: currentcolor;
        td {
          padding: 0;
          height: 1px;
        }
      }
      .scrolled-table-border-bottom {
        position: sticky; bottom: 0px;
      }
    }
  }
  table {
    // max-height: 15em;
    // overflow: auto;
    // display: block;
    // thead {
    //   position: sticky;
    //   top: 0;
    //   background: inherit;
    // }
    // thead, tbody {
    //   display: table;
    //   width: 100%;
    // }

    width: 100%;
    background: gold !important;
    border-collapse: collapse;
    border: 1px solid currentcolor;
    border-top: 0;
    thead {
      font-weight: bold;
      // border-bottom: 1px solid currentcolor;
    }
    tbody tr:not(:first-child).entry {
      // border-top: .5px solid currentcolor;
      // border-top: 1px solid currentcolor;
    }
    tbody tr:nth-child(2n - 1) {
      background: #0002;
    }
    td {
      vertical-align: middle;
    }
    td:first-child {
      padding-left: .25em;
    }
    td:last-child {
      padding-right: .25em;
    }
  }
}
#leaderboard-section table, #chart-section .chart-container {
  background: var(--id-color-text-readable);
  border: 1px solid currentcolor;
}
#chart-section .chart-container {
  width: 100%; max-width: 40em;
  padding: .5em;
  background: var(--id-color-text);
  color: var(--id-color-text-readable);
  border: 1px solid var(--id-color-text);

  // background: var(--id-color);
}

.section:last-child {
  margin-bottom: 0;
}
`